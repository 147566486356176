import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { stringify } from 'query-string'
import {
    fetchEntryData,
    fetchEntryDataAsync,
    setEntryDataState,
} from '../../actions/app/entryData.actions'
import { ApiRequest } from '../../apiRequest'
import { getEntryApiUrl } from '../../../constants/apiUrls'
import initParametersSelector from '../../selectors/initParametersSelector'
import { fetchCarlineData } from '../../actions/app/carlineData.actions'
import { applyBrand } from '../../actions/app/brand.actions'

// eslint-disable-next-line max-len
const entryDataMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchEntryData, action)) {
        const state = getState()
        const params = initParametersSelector(state)
        const paramsWithoutContextAndFeatures = {
            ...params,
        }

        delete paramsWithoutContextAndFeatures.contextId
        delete paramsWithoutContextAndFeatures.features

        const requestParams = stringify(paramsWithoutContextAndFeatures, {
            skipNull: true,
        })

        apiRequest({
            options: {
                url: getEntryApiUrl(params.contextId, requestParams),
                method: 'GET',
            },
            asyncActions: fetchEntryDataAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchEntryDataAsync.success, action)) {
        dispatch(setEntryDataState(action.payload))
        dispatch(applyBrand())
        dispatch(fetchCarlineData())
    }
}

export default entryDataMiddleware
