import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import useOnMount from '../../hooks/useOnMount'

export type Props = {
    checkoutIsEnabled: boolean
    startCheckout: Function
}

const Checkout: FunctionComponent<Props> = (props) => {
    const { checkoutIsEnabled, startCheckout } = props
    const { t } = useTranslation()

    useOnMount(() => {
        if (checkoutIsEnabled) {
            startCheckout()
        }
    })

    return (
        <>
            <h1>
                {t('checkout.heading')}
            </h1>
            <p>
                {t('checkout.infoText')}
            </p>
        </>
    )
}

export default Checkout
