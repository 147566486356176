import { createSelector } from 'reselect'
import configurationIsBuildableSelector from './configuration/configurationIsBuildableSelector'
import entryDataSelector from './entryDataSelector'

const checkoutIsEnabledSelector = createSelector(
    configurationIsBuildableSelector,
    entryDataSelector,
    (configurationIsBuildable, entryData): boolean => (
        configurationIsBuildable && entryData.links && entryData.links.orderUrl !== undefined
    ),
)

export default checkoutIsEnabledSelector
