import React, { FunctionComponent } from 'react'
import AudiLogoIcon from '../../assets/media/logos/audi-logo.svg'
import VwLogoIcon from '../../assets/media/logos/vw-logo.svg'
import VwnLogoIcon from '../../assets/media/logos/vwn-logo-de.svg'
import SeatLogoIcon from '../../assets/media/logos/seat-logo.svg'
import SkodaLogoIcon from '../../assets/media/logos/skoda-logo.svg'
import ManLogoIcon from '../../assets/media/logos/man-logo.svg'
import style from './logo.css'

export type Props = {
    isHidden: boolean
    brand: string
    country: string
}

const Logo: FunctionComponent<Props> = (props) => {
    const { isHidden, brand, country } = props
    const logoMap = {
        audi: AudiLogoIcon,
        vw: VwLogoIcon,
        vwn: VwnLogoIcon,
        seat: SeatLogoIcon,
        skoda: SkodaLogoIcon,
        man: ManLogoIcon,
        cupra: SeatLogoIcon,
    }

    const brandWithOrWithoutCountry = (logoMap[`${brand}-${country}`] !== undefined)
        ? `${brand}-${country}`
        : brand
    const LogoIcon = logoMap[brandWithOrWithoutCountry]

    const wrapperClassName = [
        style.wrapper,
        style[`wrapper-${brandWithOrWithoutCountry}`],
        isHidden ? style.hidden : '',
    ].join(' ')

    const iconClassName = [
        style.icon,
        style[`icon-${brandWithOrWithoutCountry}`],
    ].join(' ')

    return (
        <div className={wrapperClassName}>
            <LogoIcon className={iconClassName} />
        </div>
    )
}

export default Logo
