import { connect, MapStateToProps } from 'react-redux'
import navLinksSelector from '../../redux/selectors/navLinksSelector'
import { Props } from './Navigation'
import abortUrlIsSetSelector from '../../redux/selectors/url/abortUrlIsSetSelector'

type StateProps = Pick<Props, 'navLinks' | 'abortIsEnabled'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    navLinks: navLinksSelector(state),
    abortIsEnabled: abortUrlIsSetSelector(state),
})

export default connect(mapStateToProps)
