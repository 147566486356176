import { createSelector } from 'reselect'
import featureScopesSelector from './featureScopesSelector'
import orderUrlIsSetSelector from '../url/orderUrlIsSetSelector'

const checkoutIsHiddenSelector = createSelector(
    featureScopesSelector,
    orderUrlIsSetSelector,
    (featureScopes, orderUrlIsSet): boolean => (
        featureScopes.includes('hideCheckout')
        || !orderUrlIsSet
    ),
)

export default checkoutIsHiddenSelector
