import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import CarlineSelection from '../carline-selection'
import {loadConfigurationByVehicleCode} from '../../redux/actions/app/vehicleCode.actions'
import {useDispatch} from 'react-redux'
import {loadConfigurationByPrString} from '../../redux/actions/app/prString.actions'

const BasePathHandler: React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [shouldRenderCarlineSelection, setShouldRenderCarlineSelection] = useState(false)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        let audiCode = queryParams.get('audiCode')
        if(!audiCode) {
            audiCode = queryParams.get('vehicleCode')
        }
        const prString = queryParams.get('prString')

        if (audiCode) {
            dispatch(loadConfigurationByVehicleCode(audiCode))
            return
        } else if (prString) {
            dispatch(loadConfigurationByPrString(prString))
            return
        }
        setShouldRenderCarlineSelection(true)
    }, [location, dispatch])

    if (shouldRenderCarlineSelection) {
        return <CarlineSelection/>
    }

    return <></>
}

export default BasePathHandler
