import { createReducer } from 'typesafe-actions'
import EntryData from '../../entities/EntryData'
import { resetEntryDataState, setEntryDataState } from '../actions/app/entryData.actions'

export type EntryDataState = EntryData

export const defaultState: EntryDataState = {
    sessionId: '',
    language: '',
    country: '',
    currency: '',
    featureScopes: [],
    additional: [],
    links: {},
    priceViewFlags: [],
    viewFilter: [],
    viewAttributes: [],
    renderImagesOrdering: [],
    targets: [],
}

const entryDataReducer = createReducer(defaultState)
    .handleAction(setEntryDataState, (state, action) => action.payload)
    .handleAction(resetEntryDataState, () => defaultState)

export default entryDataReducer
